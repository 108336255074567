import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { captureException } from '@sentry/react';
import styled from 'styled-components';


import Receipt from './BillingReceipt';
import NewButton from '../NewButton';
import { Error } from '../Error';

import * as vars from '../../styles/variables';
import getEligibleUpgrades from '../../utils/getUpgradeItems';
import postCreateRefund from '../../utils/postCreateRefund';
import postRegisterLicense from '../../utils/postRegister';
import postSubscribe from '../../utils/postSubscribe';
import { getPurchaseData } from '../../utils/getUserData';
import { checkCartSubscriptions, checkCartUpgrades } from '../../utils/handleCartCheck';
import { SSKey, sessionStorageService } from '../../utils/handleSessionStorage';

const CardForm = styled.form`
    max-width: 540px;
    margin: auto;
    
    label {
        font-weight: 500;
    }

    > h2 {
        color: ${vars.whiteColor};
        text-align: center;
        font-size: 30px;
    }
`;

const FormContainer = styled.div`
    background-color: ${vars.whiteColor};
    padding: 60px;
    border-radius: 7px;
`;

const EmailForReceipt = styled.div`
    margin: 20px auto;
    text-align: center;
    font-weight: 500;

    > span {
        display: block;
        font-size: 18px;
        font-weight: 700;
    }
`;

const NoRefundText = styled.p`
    font-size: 12px;
`;

const CardLabel = styled.label`
    display: block;
    margin-bottom: 10px;
`;

const CardContainer = styled.div`
    border-radius: 3px;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #909090;
    line-height: 1.5em;
    font-size: 16px;
    margin-bottom: 20px;
`;

const StripeText = styled.div`
    margin: 20px auto;
    max-width: 400px;
    p {
        font-size: 12px;
    }
`;

interface CardInformationProps {
    companyInformation: {[key:string]: string};
    customerID: string;
    customerInformation: {[key:string]: string};
    jurisdiction: string | undefined;
    paymentIntentID: string;
    paymentIntentCS: string;
    setupIntentID?: string;
    setupIntentCS?: string;
    taxRate: string;
    setSuccess: React.Dispatch<React.SetStateAction<{[key:string]: boolean}>>;
    setSuccessCart: React.Dispatch<React.SetStateAction<{
        licenses?: Array<{displayName: string; standardOrPro: string;}>;
        subscriptions?: Array<string>;
        upgrades?: Array<{displayName: string; standardOrProUpgrade: string;}>;
    }>>;
}

const CardInformation: React.FC<CardInformationProps> = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);
    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);
    const signedInAccount = useSelector((state: OmniStore.GlobalReduxState) => ({ account: state.accounts.expandedUserInfo,}));

    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart,}));
    const subscriptionsInCart = cartItems.cart.subscriptions;
    const licensesInCart = cartItems.cart.licenses;
    const upgradesInCart = cartItems.cart.upgrades;

    const [paymentMethodPI, setPaymentMenthodPI] = React.useState<string | null>(null);
    const [cardComplete, setCardComplete] = React.useState<boolean>(false);
    const [cardError, setCardError] = React.useState<null | string>(null);
    const [purchaseAPIError, setPurchaseAPIError] = React.useState<null | string>(null);
    const [cartError, setCartError] = React.useState<null | string>(null);
    const [refundStatus, setRefundStatus] = React.useState<null | string>(null); 

    const [loading, setLoading] = React.useState<boolean>(false);

    const checkCartItems = async ({type}: {type: string}) => {        
        //double check upgrades and subscriptions
        if (type === 'upgrades') {
            const upgradesInfo = await getEligibleUpgrades({accessToken, purchaseApiUrl});

            if (upgradesInfo.availableUpgrades && upgradesInfo.availableUpgrades.has_upgrades_available === true) {
                return checkCartUpgrades({
                    cartUpgrades: upgradesInCart,
                    eligibleUpgrades: upgradesInfo.availableUpgrades.upgrades_available,
                }) as boolean;
            }
        } else {
            const subscriptionInfo = await getPurchaseData(accessToken, accountsApiUrl);

            if (subscriptionInfo) {
                return checkCartSubscriptions({
                    cartItems: subscriptionsInCart,
                    purchasedSubscriptions: subscriptionInfo.purchasesData
                }) as boolean;
            }
        }
    };

    const handleCardChange = (event: StripeCardElementChangeEvent) => {
        if (event.complete) {
            setCardComplete(true);
            return;
        } 
        setCardComplete(false);
    };

    const handleCreditCardSubmission = async (event: React.FormEvent) => {
        setLoading(true);
        event.preventDefault();
        //if stripe.js isn't loaded yet or card information not complete
        if (!stripe || !elements || !cardComplete) return;

        const cardElement = elements.getElement(CardElement);
        //license + upgrade purchases
        if (
            props.paymentIntentCS !== null && 
            (licensesInCart.length > 0 || upgradesInCart.length > 0)
        ) {
            if (cardElement !== null) {
                const paymentResult = await stripe.confirmCardPayment(props.paymentIntentCS, {
                    payment_method: {
                        billing_details: {
                            address: {
                                city: props.customerInformation.customerCity,
                                country: props.customerInformation.customerCountry,
                                line1: props.customerInformation.customerAddressLineOne,
                                line2: props.customerInformation.ustomerAddressLineTwo,
                                postal_code: props.customerInformation.customerPostalCode,
                                state: props.customerInformation.customerRegion,
                            },
                            name: props.customerInformation.customerNameOnCard,
                        },
                        card: cardElement,
                    },
                });

                if (paymentResult.error) {
                    if (
                        paymentResult.error.type === 'card_error' || 
                        paymentResult.error.type === 'invalid_request_error' ||
                        paymentResult.error.code === 'card_declined'
                    ) {
                        captureException(paymentResult.error);
                    }
                    setCardError(
                        'An error occurred processing the card information above. Review the card details, then try again.'
                    );
                    setLoading(false);
                    return;
                }
                if (paymentResult.paymentIntent) {
                    //save payment method in case needed for subscription purchase
                    setPaymentMenthodPI(paymentResult.paymentIntent.payment_method as string);
                    // double check cart information one last time
                    const goodToGo = await checkCartItems({type: 'upgrades'});

                    if (goodToGo === false) {
                        // shows error then navigates back to cart after 3 sec
                        setCartError(
                            'There seems to be a problem regarding the items in your cart. You will be redirected to your cart. If this persists, please contact sales@omnigroup.com'
                        );
                        setTimeout(() => {
                            navigate('/cart');
                        }, 3000)
                    } else {
                        postRegisterLicense({
                            accessToken,
                            customer_id: props.customerID,
                            payment_intent_id: paymentResult.paymentIntent.id,
                            purchaseApiUrl,
                        })
                            .then((response) => {
                                if (response.status === 'error') {
                                    setCardError(
                                        'There was an issue processing your order. Please contact sales at sales@omnigroup.com'
                                    );

                                    if (response.should_trigger_refund) {
                                        postCreateRefund({
                                            accessToken,
                                            customer_id: props.customerID,
                                            payment_intent_id: paymentResult.paymentIntent.id,
                                            purchaseApiUrl,
                                        })
                                            .then((response) => {
                                                if (response.status === 'success') {
                                                    setRefundStatus('We had a problem registering your purchase. The payment was processed, so we’ve automatically issued a refund. We’d love to figure out what happened — please email sales@omnigroup.com');
                                                } else {
                                                    setRefundStatus('We had a problem registering your purchase. Please contact sales at sales@omnigroup.com for help.');
                                                }
                                            })
                                            .catch((error) => console.log('There was an error creating refund: ', error));
                                    }
                                    setLoading(false);
                                } else {
                                    sessionStorageService.remove(SSKey.PaymentIntentID);
                                    sessionStorageService.remove(SSKey.PaymentIntentClientSecret);
    
                                    const registerdLicenses = licensesInCart.map((license) => ({
                                        displayName: license.displayName,
                                        standardOrPro: license.standardOrPro
                                    }));
                                    const registeredUpgrades = upgradesInCart.map((license) => ({
                                        displayName: license.displayName,
                                        standardOrProUpgrade: license.standardOrProUpgrade
                                    }));
                                    props.setSuccess((prevState) => ({...prevState, licenses: true, upgrades: true}));
                                    props.setSuccessCart((prevState) => ({
                                        ...prevState, 
                                        licenses: registerdLicenses,
                                        upgrades: registeredUpgrades,
                                    }))
                                }
                            })
                            .catch((error) => console.log('unable to register payment intent: ', error));
                    }
                }
            }
        }  
        //subscription purchases      
        if (props.setupIntentID !== null && subscriptionsInCart.length > 0) {
            if (cardElement !== null) {
                const subscriptionPayment = await stripe.confirmCardSetup(props.setupIntentCS, {
                    payment_method: {
                        billing_details: {
                            address: {
                                city: props.customerInformation.customerCity,
                                country: props.customerInformation.customerCountry,
                                line1: props.customerInformation.customerAddressLineOne,
                                line2: props.customerInformation.customerAddressLineTwo,
                                postal_code: props.customerInformation.customerPostalCode,
                                state: props.customerInformation.customerRegion,
                            },
                            name: props.customerInformation.customerNameOnCard,
                        },
                        card: cardElement,
                    }
                });

                if (subscriptionPayment.error) {
                    if (subscriptionPayment.error.type === 'card_error') {
                        setCardError(
                            'An error occurred processing the card information above. (It might be an incorrect number, or insufficient funds.)'
                        );
                        return;
                    }
                    setCardError(
                        'An error occurred processing the card information above. Review the card details, then try again.'
                    );
                    captureException(subscriptionPayment.error);
                    setLoading(false);
                }
                if (subscriptionPayment.setupIntent) {
                    if (subscriptionPayment.setupIntent.status === 'succeeded') {
                        //double check if subscription is available for purchase one last time
                        const goodToGo = await checkCartItems({type: 'subscriptions'});

                        if (goodToGo === false) {
                            // navigates back to cart after 3 seconds
                            setCartError(
                                'There seems to be a problem regarding the items in your cart. You will be redirected to your cart. If this persists, please contact sales@omnigroup.com'
                            );
                            setLoading(false);
                            setTimeout(() => {
                                navigate('/cart');
                            }, 3000);
                        } else {
                            const arrayOfPromises = subscriptionsInCart.map((subscription) => {
                                postSubscribe({
                                    accessToken,
                                    customer_id: props.customerID,
                                    email: signedInAccount.account.email,
                                    jurisdiction: props.jurisdiction,
                                    payment_method_id: paymentMethodPI ? paymentMethodPI : subscriptionPayment.setupIntent.payment_method,
                                    plans: [ 
                                        {
                                            plan_id:
                                            subscription.payInterval === 'MONTHLY'
                                                ? subscription.monthlyStripeID
                                                : subscription.yearlyStripeID,
                                            quantity: subscription.quantity,
                                        }
                                    ],
                                    purchaseApiUrl,
                                    tax_rate: props.taxRate,
                                    metadata: props.companyInformation ? {
                                        additionalNotes: props.companyInformation.additionalNotes,
                                        companyCustomerCountry: props.companyInformation.companyCountry,
                                        companyCustomerPostalCode: props.companyInformation.companyPostalCode,
                                        companyCustomerRegion: props.companyInformation.companyRegion,
                                        companyName: props.companyInformation.companyName,
                                        companyaddressOne: props.companyInformation.companyAddressLineOne,
                                        companyaddressTwo: props.companyInformation.companyAddressLineTwo,
                                        companycity: props.companyInformation.companyCity,
                                        customerVATID: props.companyInformation.customerVATID,
                                    } : null
                                })
                                    .catch((err) => {
                                        setPurchaseAPIError(
                                            'There was an error processing your purchase. Please contact sales at sales@omnigroup.com'
                                        );
                                        console.log(err)
                                    });
                                return subscription;
                            });

                            Promise.all(arrayOfPromises)
                                .then(() => {
                                    sessionStorageService.remove(SSKey.SetupIntentID);
                                    sessionStorageService.remove(SSKey.SetupIntentClientSecret);

                                    const registeredSubscriptions = subscriptionsInCart.map((product) => product.displayName);
                                    props.setSuccess((prevState) => ({...prevState, subscriptions: true}));
                                    props.setSuccessCart((prevState) => ({...prevState, subscriptions: registeredSubscriptions}));
                                })
                        }
                    }
                }
            }
        }
    };

    return (
        <CardForm onSubmit={handleCreditCardSubmission}>
            <h2>Your Order</h2>
            <FormContainer>
                {cartError && <Error>{cartError}</Error>}
                {refundStatus && <Error>{refundStatus}</Error>}
                <Receipt taxRate={props.taxRate}/>
                <EmailForReceipt>
                    <p>
                        <FormattedMessage id='receiptFor' />
                    </p>
                    <span>{signedInAccount.account.email}</span>
                </EmailForReceipt>
                {subscriptionsInCart.length > 0 && (
                    <>
                        <NoRefundText>
                            <FormattedMessage id='autoRenewText' />
                        </NoRefundText>
                        <NoRefundText>
                            <FormattedMessage id='noRefundText' />
                        </NoRefundText>
                    </>
                )}
                <CardLabel htmlFor="creditCard">Card:</CardLabel>
                <CardContainer>
                    <CardElement onChange={handleCardChange} />
                </CardContainer>
                {cardError && <Error>{cardError}</Error>}
                <StripeText>
                    <p>
                        <FormattedMessage id='iAuthorizeOmniGroupToUse' />
                    </p>
                </StripeText>
                {purchaseAPIError ? (
                    <Error>{purchaseAPIError}</Error>
                ) : (
                    <NewButton
                        disabled={cardComplete !== true || loading}
                        center
                        id='CompletePurchaseButton'
                        minWidth='300px'
                        showLoading={loading}
                    >
                        Complete Purchase
                    </NewButton>
                )}
            </FormContainer>
        </CardForm>
    )
}

export default CardInformation;
