import * as React from 'react';
import { List } from 'react-content-loader';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Error } from '../Error';
import NewButton from '../NewButton';
import BillingAddessForm from './BillingAddessForm';
import * as vars from '../../styles/variables';

import postTaxRate from '../../utils/postTaxRate';
import { postUpdatePaymentIntent } from '../../utils/postUpdatePaymentIntent';
// import { postUpdateSetupIntent } from '../../utils/postUpdateSetupIntent';
import { createUpgradeJSON, createLicenseJSON } from '../../utils/createPaymentIntentJson';

import { SSKey, sessionStorageService } from '../../utils/handleSessionStorage';

const BillingAddressForm = styled.form`
    max-width: 500px;
    margin: auto;
    
    label {
        font-weight: 500;
    }

    > h3 {
        color: ${vars.whiteColor};
        text-align: center;
        font-size: 30px;
    }
`;

const OptionalBusinessSection = styled.div`
    padding: 10px;
    background-color: #f6f8fa;

    > * {
        font-size: 14px;
    }

    > div {
        background-color: #f6f8fa;
    }

    span {
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
    }

    p {
        font-size: 14px;
        margin: 5px 0;
    }

    input {
        margin: 5px 0 10px 0;
        font-size: 12px;
        padding: 8px 6px;
        height: unset;
    }

    form {
        font-size: 12px;
    }
`;

const FormContainer = styled.div`
    background-color: ${vars.whiteColor};
    padding: 60px;
    border-radius: 7px;
    margin: auto;
`;

const InputRowWrap = styled.div`
    margin: 10px 0;
`;

const InputField = styled.input`
    width: 100%;
`;

const AddCompanyAddress = styled.p`
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
`;

interface BillingDetailsProps {
    customerID: string;
    paymentIntentID: string;
    paymentIntentCS: string;
    setupIntentID: string;
    setupIntentCS: string;
    setCustomerInformation: React.Dispatch<React.SetStateAction<{[key:string]: string}>>;
    setCompanyInformation: React.Dispatch<React.SetStateAction<{[key:string]: string}>>;
    setTaxRate: React.Dispatch<React.SetStateAction<OmniStore.TaxRateInformation>>;
}

const BillingDetails: React.FC<BillingDetailsProps> = (props) => {
    const navigate = useNavigate();
    
    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);
    const taxserviceURL = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.taxserviceURL);
    const cartFromAPI = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.serverCart,}));

    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart }));
    const licensesInCart = cartItems.cart.licenses;
    const upgradesInCart = cartItems.cart.upgrades;
    const subscriptionsInCart = cartItems.cart.subscriptions;

    const [customerNameOnCard, setCustomerNameOnCard] = React.useState<string>('');
    const [customerAddressLineOne, setCustomerAddressLineOne] = React.useState<string>('');
    const [customerAddressLineTwo, setCustomerAddressLineTwo] = React.useState<string>('');
    const [customerCountry, setCustomerCountry] = React.useState<string>('');
    const [customerRegion, setCustomerRegion] = React.useState<string>('');
    const [customerCity, setCustomerCity] = React.useState<string>('');
    const [customerPostalCode, setCustomerPostalCode] = React.useState<string>('');

    const [addCompanyOrVAT, setAddCompanyOrVAT] = React.useState<boolean>(false);
    const [customerVATID, setCustomerVATID] = React.useState<string>('');
    const [companyName, setCompanyName] = React.useState<string>('');

    const [addCompanyAddress, setAddCompanyAddress] = React.useState<boolean>(false);
    const [companyAddressLineOne, setCompanyAddressLineOne] = React.useState<string>('');
    const [companyAddressLineTwo, setCompanyAddressLineTwo] = React.useState<string>('');
    const [companyCountry, setCompanyCountry] = React.useState<string>('');
    const [companyRegion, setCompanyRegion] = React.useState<string>('');
    const [companyCity, setCompanyCity] = React.useState<string>('');
    const [companyPostalCode, setCompanyPostalCode] = React.useState<string>('');
    const [additionalNotes, setAdditionalNotes] = React.useState<string>("");

    const [formError, setFormError] = React.useState<null | string>(null);
    const [addressError, setAddressError] = React.useState<boolean>(false);
    const [paymentIntentError, setPaymentIntentError] = React.useState<null | string>(null);
    const [setupIntentError, setSetupIntentError] = React.useState<null | string>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    const { customerID, setTaxRate, setCustomerInformation, setCompanyInformation } = props;

    const checkBillingAddress = () => {
        return new Promise((resolve) => {
            if (customerRegion === '' ||
                customerNameOnCard === '' ||
                customerCountry === '' ||
                customerPostalCode === '' ||
                customerCity === '' || 
                customerAddressLineOne === ''
            ) {
                setFormError('Make sure the entire billing address is complete.')
                setLoading(false);
                return resolve(false);
            }
            if (customerCountry === 'US') {
                if (customerPostalCode.length < 5) {
                    setFormError('Make sure the ZIP code is valid for a United States address.')
                    setLoading(false);
                    return resolve(false);
                }
                if (customerRegion === 'WA') {
                    if (!customerPostalCode.startsWith('98') && !customerPostalCode.startsWith('99')) {
                        setFormError('Make sure the ZIP code is valid for a Washington address.')
                        setLoading(false);
                        return resolve(false);
                    }
                    const zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
                    if (!zipCodePattern.test(customerPostalCode)) {
                        setFormError( 'Make sure the ZIP code is valid for a United States address.')
                        setLoading(false);
                        return resolve(false);
                    }
                }
            }
            setCustomerInformation({
                customerAddressLineOne,
                customerAddressLineTwo,
                customerCity,
                customerCountry,
                customerNameOnCard,
                customerPostalCode,
                customerRegion,
            })
            return resolve(true);
        })
    };

    const checkCompanyAddress = () => {
        if (
            companyCountry.length > 0 ||
            companyPostalCode.length > 0 ||
            companyRegion.length > 0 ||
            companyName.length > 0 ||
            companyAddressLineOne.length > 0 ||
            companyAddressLineTwo.length > 0 ||
            companyCity.length > 0 ||
            additionalNotes.length > 0 ||
            customerVATID.length > 0
        ) {
            setCompanyInformation({
                additionalNotes,
                companyAddressLineOne,
                companyAddressLineTwo,
                companyCity,
                companyCountry,
                companyName,
                companyPostalCode,
                companyRegion,
                customerVATID,
            })
            return true;
        }
        return false;
    }

    // const updateSetupIntent = async () => {
    //     const companyInformation = checkCompanyAddress();
    //     const metaData = {
    //         additionalNotes: additionalNotes,
    //         companyCustomerCountry: companyCountry,
    //         companyCustomerPostalCode: companyPostalCode,
    //         companyCustomerRegion: companyRegion,
    //         companyName: companyName,
    //         companyaddressOne: companyAddressLineOne,
    //         companyaddressTwo: companyAddressLineTwo,
    //         companycity: companyCity,
    //         customerVATID: customerVATID,
    //     }; 

    //     if (companyInformation === true) {
    //         postUpdateSetupIntent({
    //             accessToken,
    //             purchaseApiUrl,
    //             customer_id: customerID,
    //             metadata: metaData,
    //             setupIntentID: props.setupIntentID,
    //         })
    //             .then((response) => {
    //                 if (response.error) {
    //                     setLoading(true);
    //                     setSetupIntentError(response.error);
    //                     setTimeout(() => {
    //                         navigate('/cart')
    //                     }, 3000);
    //                 }
    //             })
    //             .catch((error) => console.log('unable to update payment intent', error))
    //     }
    // }

    const updatePaymentIntent = async ({
        jurisdiction,
        taxRate,
        updatedAmount
    }: {
        jurisdiction?: string | undefined;
        taxRate: string;
        updatedAmount: number;
    }) => {
        const companyInformation = checkCompanyAddress();
        const paymentIntentID = sessionStorageService.get(SSKey.PaymentIntentID);

        const licensesJSON = createLicenseJSON(licensesInCart);
        const upgradeJSON = createUpgradeJSON(upgradesInCart);

        const metaData = {
            additionalNotes: additionalNotes,
            companyCustomerCountry: companyCountry,
            companyCustomerPostalCode: companyPostalCode,
            companyCustomerRegion: companyRegion,
            companyName: companyName,
            companyaddressOne: companyAddressLineOne,
            companyaddressTwo: companyAddressLineTwo,
            companycity: companyCity,
            customerVATID: customerVATID,
        };    

        if (companyInformation === true) {
            postUpdatePaymentIntent({
                accessToken,
                cart: {
                    new_purchases: licensesJSON,
                    upgrades: upgradeJSON,
                },
                customer_id: customerID,
                jurisdiction,
                metadata: metaData,
                paymentIntentID,
                purchaseApiUrl,
                tax_rate: taxRate,
                updatedAmount:
                    updatedAmount !== null
                        ? parseInt(updatedAmount.toFixed(0))
                        : cartFromAPI.cart.pricing.final_price,
            })
                .then((response) => {
                    //if unable to update payment intent
                    if (response.error) {
                        setLoading(true);
                        setPaymentIntentError(response.error);
                        setTimeout(() => {
                            navigate('/cart')
                        }, 3000);
                    }
                })
                .catch((error) => console.log('unable to update payment intent', error));
        } else {
            postUpdatePaymentIntent({
                accessToken,
                cart: {
                    new_purchases: licensesJSON,
                    upgrades: upgradeJSON,
                },
                customer_id: customerID,
                jurisdiction,
                paymentIntentID,
                purchaseApiUrl,
                tax_rate: taxRate,
                updatedAmount:
                    updatedAmount !== null
                    ? parseInt(updatedAmount.toFixed(0))
                    : cartFromAPI.cart.pricing.final_price,
            })
                .then((response) => {
                    //if unable to update payment intent
                    if (response.error) {
                        setLoading(true);
                        setPaymentIntentError(response.error);
                        setTimeout(() => {
                            navigate('/cart')
                        }, 3000);
                    }
                })
                .catch((error) => console.log('unable to update payment intent', error));
        }
    }

    const determinTaxRateAndUpdateIntent = async (): Promise<void> => {
        const cartAPIPricing = cartFromAPI.cart.pricing;
        const cartAPIMessage = cartFromAPI.cart.message;
        
        if (cartAPIPricing === undefined && cartAPIMessage === undefined) {
            setFormError('The purchase server is not responding. Please try again later.');
        }

        let jurisdictionForStripeStorage: string | null;
        let taxRate: string;
        

        const taxserviceCall = await postTaxRate({
            city: customerCity,
            country: customerCountry,
            line1: customerAddressLineOne,
            line2: customerAddressLineTwo,
            postalCode: customerPostalCode,
            state: customerRegion,
            taxServiceURL: taxserviceURL,
        });

        if (taxserviceCall.errors) {
            setAddressError(true);
            setLoading(false);
            return;
        }
        // if it's WA or VA
        if (taxserviceCall.has_tax_info) {
            taxRate = taxserviceCall.tax_info.tax_rate;
            jurisdictionForStripeStorage = taxserviceCall.tax_info.jurisdiction;

            setAddressError(false);
            setTaxRate({
                jurisdiction: jurisdictionForStripeStorage,
                tax_rate: taxRate,
            });

            let updatedAmount: number;
            if (cartAPIPricing !== undefined) {
                if (taxRate === '0') {
                    updatedAmount = cartAPIPricing.final_price ? cartAPIPricing.final_price : null;
                }
                updatedAmount = cartAPIPricing.final_price + parseFloat(taxRate) * cartAPIPricing.final_price;
            } else {
                updatedAmount = cartAPIPricing.final_price;
            }

            if (licensesInCart.length > 0 || upgradesInCart.length > 0) {
                updatePaymentIntent({
                    jurisdiction: jurisdictionForStripeStorage,
                    taxRate,
                    updatedAmount
                })
            }
            if (subscriptionsInCart.length > 0) {
                checkCompanyAddress();
            }
        } else {
            //if is not a taxable state 
            if (licensesInCart.length > 0 || upgradesInCart.length > 0) {
                updatePaymentIntent({
                    taxRate,
                    updatedAmount: cartAPIPricing.final_price
                })
            }
            if (subscriptionsInCart.length > 0) {
                checkCompanyAddress();
            }
        }
        setLoading(false);
    };

    const handleSubmit = async (event: React.FormEvent): Promise<void> => {
        setLoading(true);
        event.preventDefault();

        const billingAddress = await checkBillingAddress();

        if (billingAddress === true) {
            determinTaxRateAndUpdateIntent();
        }
    }

    if (paymentIntentError) {
        return (
            <div>
                <div style={{color: 'white'}}>{paymentIntentError}</div>
                <List/>
            </div>
        )
    }

    if (setupIntentError) {
        return (
            <div>
                <div style={{color: 'white'}}>{setupIntentError}</div>
                <List/>
            </div>
        )
    }

    return (
        <BillingAddressForm onSubmit={handleSubmit}>
            <h3>Billing Details</h3>
            <FormContainer>
                <InputRowWrap>
                    <label htmlFor='customer-name'>Name on card:</label>
                    <InputField
                        id="customer-name"
                        type='text'
                        value={customerNameOnCard}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => 
                            setCustomerNameOnCard(e.currentTarget.value)}
                    />
                </InputRowWrap>
                <BillingAddessForm
                    formFor="customer"
                    addressLineOne={customerAddressLineOne}
                    addressLineTwo={customerAddressLineTwo}
                    city={customerCity}
                    country={customerCountry}
                    postalCode={customerPostalCode}
                    region={customerRegion}
                    changeAddressLineOne={setCustomerAddressLineOne}
                    changeAddressLineTwo={setCustomerAddressLineTwo}
                    selectCountry={setCustomerCountry}
                    selectRegion={setCustomerRegion}
                    addCity={setCustomerCity}
                    addPostalCode={setCustomerPostalCode}
                />
                <OptionalBusinessSection>
                    <div>
                        <span onClick={() => setAddCompanyOrVAT(!addCompanyOrVAT)}>
                            Add company name, VAT ID, or address?
                        </span>
                        <p>
                            These will be added to an optional invoice you can
                            request.
                        </p>
                    </div>
                    {addCompanyOrVAT && (
                        <div>
                            <label htmlFor='customerVATID'>VAT ID:</label>
                            <InputField
                                type='text'
                                id='customerVATID'
                                value={customerVATID}
                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                    setCustomerVATID(e.currentTarget.value)}
                            />
                            <label htmlFor='companyName'>Company name:</label>
                            <InputField
                                type='text'
                                id='companyName'
                                value={companyName}
                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                    setCompanyName(e.currentTarget.value)}
                            />
                            <AddCompanyAddress onClick={() => setAddCompanyAddress(!addCompanyAddress)}>
                                Company address?
                            </AddCompanyAddress>
                            {addCompanyAddress && (
                                <BillingAddessForm
                                    formFor="company"
                                    addressLineOne={companyAddressLineOne}
                                    addressLineTwo={companyAddressLineTwo}
                                    city={companyCity}
                                    country={companyCountry}
                                    postalCode={companyPostalCode}
                                    region={companyRegion}
                                    changeAddressLineOne={setCompanyAddressLineOne}
                                    changeAddressLineTwo={setCompanyAddressLineTwo}
                                    selectCountry={setCompanyCountry}
                                    selectRegion={setCompanyRegion}
                                    addCity={setCompanyCity}
                                    addPostalCode={setCompanyPostalCode}
                                />
                            )}
                            <label htmlFor='additionalNotes'>Invoice notes for your records:</label>
                            <InputField
                                type='text'
                                id='additionalNotes'
                                value={additionalNotes}
                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                    setAdditionalNotes(e.currentTarget.value)
                                }
                            />
                        </div>
                    )}
                </OptionalBusinessSection>
                {formError && <Error>{formError}</Error>}
                {addressError && <Error>There is an error with the provided address.</Error>}
                <NewButton
                    id='ContinueWithCheckoutButton'
                    center
                    minWidth='300px'
                    showLoading={loading}
                >
                    Continue
                </NewButton>
            </FormContainer>
        </BillingAddressForm>
    )
}

export default BillingDetails;
